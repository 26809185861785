.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &_close {
    width: 0.46rem;
    height: 0.46rem;
    display: block;
    margin: 0 auto;
    margin-top: 0.7rem;
  }

  &_container {
    position: relative;
  }

  &_content {
    width: 5rem;
    height: 3.77rem;
    background-size: contain;
    background-image: url('../../../../images/buy/tips_modal.png');
  }

  &_text {
    font-size: .26rem;
    color: #333;
    line-height: .48rem;
    text-align: center;
    padding-top: 1.39rem;

    &_vip {
      font-size: .28rem;
      color: #FFA42F;
    }
  }

  &_open_vip_btn {
    width: 4.08rem;
    position: absolute;
    bottom: .38rem;
    left: .46rem;
  }

  &_refresh {
    @extend .modal;
    width: 5rem;
    height: 3.63rem;
    background-image: url('../../../../images/groupMessage/modal1.png');
    background-size: contain;
    text-align: center;

    &_btn {
      width: 4.08rem;
      height: 0.62rem;
      position: absolute;
      bottom: .34rem;
      transform: translateX(-50%);
    }

    &_title {
      font-size: .32rem;
      color: #463D3B;
      line-height: .6rem;
      margin-top: .9rem;
    }

    &_content {
      font-size: .26rem;
      color: #666;
      line-height: .55rem;
    }
  }
}