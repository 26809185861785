.container {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
}

.header {
  position: relative;
}

.content {
  flex: 1;
  overflow-y: scroll;
}

.active-users, .agent-areas, .follow-types {
  background-color: #FFF;
  box-shadow: 0 .04rem .06rem 0 rgba(0, 0, 0, 0.06);
}

.tabs {
  display: flex;
  background-color: #FFF;
}

.tab {
  &_wrapper {
    flex: 1;
    margin: .2rem 0;
  }
  
  width: 1.97rem;
  line-height: .52rem;
  font-size: .32rem;
  margin: 0 auto;
  border-radius: .26rem;
  text-align: center;

  &_active {
    background-color: #37D0A8;
    color: #FFF;
  }
}

.active-users {
  padding: .1rem .37rem .1rem .5rem;
}

.active-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .2rem 0;

  &_title {
    font-size: .28rem;
    color: #333;

    &-active {
      color: #20D2A4;
    }
  }

  &_selected {
    width: .24rem;
    height: .17rem;
  }
}

.agent-areas {
  padding: .15rem .1rem;
  min-height: 5rem;
}

.agent-area {
  display: inline-block;
  margin-left: .15rem;
  line-height: .64rem;
  width: 2.2rem;
  background-color: #F9FAFB;
  border-radius: .04rem;
  text-align: center;
  font-size: .28rem;
  margin-bottom: .2rem;

  &_active {
    background-color: #20D2A4;
    color: #FFF;
  }
}

.follow-types {
  height: 100%;
  display: flex;
}

.follow-type {
  &_standards {
    background-color: #F9FAFB;

    &_wrapper {
      width: 1.8rem;
      overflow-y: scroll;
    }
  }

  &_standard {
    line-height: 1.16rem;
    padding-left: .34rem;
    font-size: .30rem;
    color: #666;

    &_active {
      background-color: #FFF;
      color: #333;
      font-size: .32rem;
    }
  }

  &_types {
    flex: 1;
    overflow-y: scroll;
  }

  padding: .4rem .2rem .16rem;

  &_title {
    font-size: .32rem;
    line-height: .44rem;
    color: #333;
  }

  &_type {
    display: inline-block;
    line-height: .84rem;
    font-size: .28rem;
    min-width: 1.56rem;
    text-align: center;
    background-color: #F9FAFB;
    border-radius: .04rem;
    margin: .2rem .1rem 0;
    padding: 0 .2rem;

    &_active {
      line-height: .80rem;
      box-sizing: border-box;
      border: .01rem solid #00D498;
      color: #00D498;
      background-color: transparent;
    }
  }
}

.next-step {
  border: none;
  height: .84rem;
  margin: .72rem .3rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%);
}