.container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
}

.content {
  flex: 1;
}

.list-item{
  margin: .3rem;

  &_card {
    box-shadow: 0 .05rem .06rem 0 rgba(0, 0, 0, 0.06);
    border-radius: .08rem;
    background-color: #FFF;
  }

  &_title, &_agent-send {
    font-size: .24rem;
    line-height: .62rem;
  }

  &_title {
    color: #999;
    padding: 0 .2rem;
  }

  &_agent-send {
    color: #666;
    text-align: center;
  }

  &_content {
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
  }

  &_time {
    &-wrapper {
      text-align: center;
      margin-bottom: .33rem;
    }

    font-size: .24rem;
    color: #FFF;
    background-color: #BCBCBC;
    border-radius: .04rem;
    padding: 0 .1rem;
    line-height: .38rem;
    display: inline-block;
  }

  &_text {
    font-size: .28rem;
    color: #333;
    padding: .1rem .2rem;
    line-height: .48rem;
  }

  &_image {
    $sideLength: 2rem;
    width: $sideLength;
    height: $sideLength;

    &-wrapper {
      padding: .2rem 0;
      display: flex;
      justify-content: center;
    }
  }
}

.product {
  padding: .32rem .24rem;
  display: flex;

  &_image {
    $sideLength: 2.12rem;
    width: $sideLength;
    height: $sideLength;
  }

  &_info {
    flex: 1;
    margin-left: .24rem;
    overflow: hidden;
  }

  &_name {
    font-size: .4rem;
    line-height: .56rem;
  }

  &_factory {
    font-size: .24rem;
    color: #AFAFB0;
    line-height: .34rem;
  }

  &_advantage {
    font-size: .28rem;
    line-height: .4rem;
    margin: .06rem 0;
  }

  &_tag {
    font-size: .24rem;
    color: #797979;
    padding: 0 .04rem;
    line-height: .34rem;
    border-radius: .08rem;
    background-color: rgba(175, 175, 175, 0.15);

    & + & {
      margin-left: .08rem;
    }
  }

  &_certifications{
    margin-top: .18rem;
  }
}

.personal{
  padding: .2rem .2rem .2rem .16rem;
  &_info {
    margin-top: .34rem;
    display: flex;

    &_content {
      margin-left: .2rem;
      flex: 1;
      overflow: hidden;
    }
  }
  &_img {
    $sideLength: .9rem;
    width: $sideLength;
    height: $sideLength;
  }

  &_company {
    display: flex;
    align-items: center;

    &_name {
      font-size: .32rem;
      line-height: .52rem;
    }

    &_view {
      margin-left: auto;
      flex-shrink: 0;
      font-size: .28rem;
      color: #999;
      display: flex;
      align-items: center;

      > img {
        width: .3rem;
        height: .3rem;
      }
    }
  }

  &_product {
   &_title, &_number {
     line-height: .4rem;
   } 

   &_title {
     font-size: .28rem;
     color: #999;
   }

   &_number {
     font-size: .32rem;
     font-weight: bold;
     margin-left: .06rem;
   }
  }
}

.certification {
  display: inline-flex;
  align-items: center;
  padding: 0 .1rem;
  border-radius: .18rem;

  &.green {
    background-color: #E3F8F3;
    color: #59C1A6;
  }

  &.yellow {
    background-color: rgba(252, 125, 26, 0.21);
    color: #F5781D;
  }

  &.blue {
    background-color: #CEF2FF;
    color: #29B4E6;
  }

  & + & {
    margin-left: .1rem;
  }
  
  &-title {
    font-size: .24rem;
    line-height: .36rem;
    margin-left: .06rem;
  }

  &-icon {
    width: .24rem;
    height: .28rem;
  }
}

.create-group-message {
  &_wrapper {
    position: sticky;
    bottom: 0;
    padding: .4rem .3rem .72rem;
    background-color: inherit;
  }

  width: 100%;
  border: none;
  height: .84rem;
  border-radius: .08rem;
  color: #FFF;
  font-size: .32rem;
  background: linear-gradient(90deg, #2FDCAF 0%, #06C392 100%);
}

.modal {
  top: 50%;
  position: absolute;
  width: 100%;

  &_container {
    &-wrapper{
      position: absolute;
      width: 100%;
      transform: translateY(-50%);
    }
    
    margin: 0 1.35rem;
    background-color: #FFF;
    border-radius: .2rem;
  }

  &_banner {
    width: 4.8rem;
    height: 2.3rem;
    vertical-align: bottom;
  }

  &_content {
    padding: .3rem .64rem;
  }

  &_title {
    font-size: .28rem;
    text-align: center;
  }

  &_btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &_btn {
    width: 1.6rem;
    line-height: .5rem;
    border-radius: .27rem;
    border: .02rem solid #999;
    text-align: center;
    margin-top: .3rem;
    color: #999;
    background-color: transparent;
  }
}

.banner {
  // margin: .3rem;
  width: 6.9rem;
  display: block;
}