html,body{
  overflow: auto; //设置scroll overflow
  -webkit-overflow-scrolling: touch; // 优化移动端滑动体验
  background-color: #fff !important;
  overflow-x hidden;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea,
menu {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header,
footer,
section,
article,
aside,
nav,
hgroup,
address,
figure,
figcaption,
menu,
details {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
  font-weight: normal;
}

html,
body,
fieldset,
img,
iframe,
abbr {
  border: 0;
}

i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}

[hidefocus],
summary {
  outline: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-weight: normal;

  font-size: 100%;
}

sup,
sub {
  font-size: 83%;
}

pre,
code,
kbd,
samp {
  font-family: inherit;
}

q:before,
q:after {
  content: none;
}

textarea {
  overflow: auto;
  resize: none;
}

label,
summary {
  cursor: default;
}

a,
button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
b {
  font-weight: bold;
}

del,
ins,
u,
s,
a,
a:hover {
  text-decoration: none;
}

body,
textarea,
input,
button,
select,
keygen,
legend {
  font: 0.24rem/1.14;
  color: #333;
  outline: 0;
}

body {
  background: #fff;
}

a,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: #233846;
}

body {
  margin: 0;
  padding: 0;
  font-family: "PingFang-SC-Regular";
  font-size: 0.26em;
}

html,
body {
  // width: 100%;

  height: 100%;
}
.yxb_h5 {
  padding-top: 0.88rem;
}

.am-picker-popup
  font-size 0.28rem
  & .am-picker-popup-header
    font-size 0.28rem
    & .am-picker-popup-item
      font-size 0.28rem
/* ::-webkit-input-placeholder{color: #E39260;} */

.single-line_text {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}